<template>
      <div class="popup-bg">
            <div class="popup">
                  <div class="div" :key="detail + 1" v-for="(detail, index) in brand">
                        <template v-if="index != 'link' & detail != null">

                              <div class="brand-mobile"><strong>{{ index.replace(/_/g, ' ') }} </strong> {{ detail }}

                              </div>
                              <div class="line"> </div>
                        </template>
                        <template v-if="index == 'Web_Comments'">
                              <a target="_blank" :href="brand.link" class="popup-button">Перейти на сайт</a>
                        </template>

                  </div>
                  <button class="close-popup" v-on:click="this.$parent.switchShowPopup()"><img src="../assets/close.svg" alt=""></button>
            </div>
      </div>
</template>

<script>

export default {
      name: 'PopupTable',
      props: {
            brand: Object

      },
}
</script>
<style>
.popup-bg {
      position: fixed;
      width: 100vw;
      height: 100%;
      background: rgba(0, 0, 0, 0.70);
      top: 0;
      left: 0;
}

.popup {
      background: white;
      width: calc(100% - 32px);
      position:relative ;
      margin: 20px auto 0;
      padding: 30px 30px;
      box-sizing: border-box;

}

.brand-mobile {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      font-size: 18px;
      padding: 12px 0;
      gap: 5px;

}

.line {
      width: 100%;
      height: 1px;
      background-color: #000;
}

.popup-button {
      width: 100%;
      height: 50px;
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      background-color: #000;
      text-decoration: none;
      font-size: 18px;
}
.close-popup{
      position: absolute;
      right: 10px;
      top: 10px;
      background-color: transparent;
      border: none;
}
</style>