<template>
      <div class="container">
            <div class="content">
                  <div class="text-block">
                        <h1>БИБЛИОТЕКА ЗНАНИЙ </h1>
                        <div class="lead">Обучающие материалы по продуктам, <br> маркетингу и продажам</div>
                  </div>

                  <div class="brands"> <img src="./assets/cover.svg" alt=""> <img src="./assets/nomad.svg" alt=""></div>
            </div>
      </div>
      <FullScreenBlock text="КРАСКИ И ORAC" img="paints"></FullScreenBlock>
      <div class="content-list">
            <div class="row">
                  <h4>Обучение по краскам для дилеров Manders</h4>
                  <p>Формат: pdf-презентация (29 слайдов)</p>
                  <a target="_blank"
                        href="https://drive.google.com/file/d/1l0OJjcur-wii9jTqe2VJhOvfUfi0W-Uf/view">Смотреть
                        презентацию</a>
            </div>
            <div class="row">
                  <h4>Работа с возражениями: краски конкурентов. Рекомендации Manders</h4>
                  <p>Распространенные примеры возражений заказчиков, делающих отсылки к краскам конкурентов - и работа с этими
                        возражениями. </p>
                  <p>Формат: google-документ (3 страницы) </p>
                  <a target="_blank"
                        href="https://docs.google.com/document/d/1kkpkFqDfz1RXFZO7vZTwCzbiCIHerJFMQChacsoiiB4/edit">Смотреть
                        документ</a>
            </div>
            <div class="row">
                  <h4>Технические особенности краски</h4>
                  <p>Химия / физика красок, технические особенности Little Greene, Paint & Paper Library и Argile. Обучение от
                        Дмитрия Панченко, главного по техническим аспектам краски в Manders</p>
                  <p>Формат: видеозапись zoom-обучения (2 часа 8 мин) </p>
                  <a target="_blank" href="https://www.youtube.com/watch?v=Z3XR_9DHyfI">Смотреть видео</a>
            </div>
            <div class="row">
                  <h4>Особенности продажи красок</h4>
                  <p>Инструменты продаж Little Greene, Paint & Paper Library и Argile. Частные вопросы от клиентов, ответы на
                        возражения. Обучение от Алексея Елисеева, основателя Manders</p>
                  <p>Формат: видеозапись zoom-обучения (2 часа 8 мин)</p>
                  <a target="_blank" href="https://www.youtube.com/watch?v=g5iS9gwWkl0">Смотреть видео</a>
            </div>
            <div class="row">
                  <h3>ДОПОЛНИТЕЛЬНЫЕ ССЫЛКИ НА ПОЛЕЗНЫЕ YOUTUBE-РОЛИКИ</h3>
                  <p>Коротко (5 мин) про особенности и преимущества английских красок.</p>
                  <a target="_blank" href="https://www.youtube.com/watch?v=SvsZRVvZ2FA">Смотреть</a>
            </div>
            <div class="row">
                  <p>Алексей Елисеев про цвет в интерьере: правила и законы (и их условность), колористические приемы,
                        инструменты работы с цветом.</p>
                  <a target="_blank" href="https://www.youtube.com/watch?v=U6Bh7RzVYS0&t=1s">Смотреть</a>
            </div>
            <div class="row">
                  <p>Дмитрий Панченко про “химию цвета”: состав краски и то, как он влияет на итоговый результат покраски.</p>
                  <a target="_blank" href="https://www.youtube.com/watch?v=QQXEqAzi2xY">Смотреть</a>
            </div>
            <div class="row">
                  <p>Дмитрий Панченко про отличительные особенности премиальных красок.</p>
                  <a target="_blank" href="https://www.youtube.com/watch?v=iotd5emuclk">Смотреть</a>
            </div>
            <div class="row">
                  <p>Дэвид Моттерсхед (основатель Little Greene / Paint & Paper Library) рассказывает о палитре Monochrome от
                        Paint & Paper Library.</p>
                  <a target="_blank" href="https://www.youtube.com/watch?v=rwoGkr_CLK0">Смотреть</a>
            </div>
            <div class="row">
                  <p>Дэвид Моттерсхед (основатель Little Greene / Paint & Paper Library) рассказывает о палитре Stone от
                        Little Greene.</p>
                  <a target="_blank" href="https://www.youtube.com/watch?v=kQMQoxvkxF0">Смотреть</a>
            </div>
            <div class="row">
                  <h3>ORAC</h3>
                  <p>Ссылка на обучающие материалы и тестирование Orac <br>
                        <a target="_blank"
                              href="https://stepik.org/invitation/e95750a0d23392bcbfa3a01d78275a9f4151db09/">Ссылка</a><br>
                        почта: malyrkaspace@gmail.com (michael rodionov / id 641955975)<br>
                        пароль: vhnmwx/z#7bql9p
                  </p>
            </div>
            <div class="row">
                  <h3>ZANOTTA </h3>
                  <p>История компании, группы товаров, особенности продуктов. </p>
                  <a target="_blank" href="https://www.youtube.com/watch?v=0RNdFwI3-kU">Смотреть видео</a>
            </div>
            <div class="row">
                  <h3>МАРКЕТИНГ</h3>
                  <h4>Strategy</h4>
                  <p>Принципы работы с клиентами; позиционирование компании; партнеры</p>
                  <a target="_blank"
                        href="https://docs.google.com/document/d/1nwLOoQzPc_UupBZbngL5u4TuKI1tE5JW/edit?usp=sharing&ouid=110260784833612271842&rtpof=true&sd=true">Смореть
                        документ</a>
            </div>
            <div class="row">
                  <h4>Продажи: стурктура и последовательность действий*</h4>
                  <p>Все этапы сделки - от консультации клиента до проверки отгружаемого товара.<br>
                        *для всех продуктов Nomad, для заказных позиций Cover (Zanotta и другая мебель, обои)</p>
                  <span> <a target="_blank"
                              href="https://docs.google.com/spreadsheets/d/162EUu7q8iKWqOinLwTnBD07ar7vAijCv/edit?usp=sharing&ouid=110260784833612271842&rtpof=true&sd=true">Смотреть
                              документ </a>
                        <a target="_blank" style="margin-left: 10px;"
                              href="https://docs.google.com/document/d/1QPF0gcHbfhwcB7MpxeaeVmAiJEdyyhEY/edit?usp=sharing&ouid=110260784833612271842&rtpof=true&sd=true">(вариант
                              на английском) </a>
                  </span>
            </div>
      </div>
      <HelloWorld msg="Welcome to Your Vue.js App" />
      <FullScreenBlock text="БРЕНДЫ NOMAD" img="brands"></FullScreenBlock>
      <div class="content-list">
            <BrandTable />
      </div>
</template>

<script>
import FullScreenBlock from './components/FullScreenBlock.vue'
import BrandTable from './components/BrandTable.vue'
export default {
      name: 'App',
      components: {
            BrandTable,
            FullScreenBlock
      }
}
</script>

<style>
#app {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
}

.container {
      width: 100%;
      height: 100vh;
      /* background-image: url(./assets/paints.webp); */
      background-size: cover;
      background-position: top center;
}

.content {
      width: 1300px;
      height: 100%;
      margin: 0 auto;
      position: relative;
}

.content-list {
      width: 1300px;
      margin: 0 auto;
      padding: 80px 0;
      display: flex;
      flex-direction: column;
      gap: 30px;
}

.brands {
      display: flex;
      gap: 80px;
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      bottom: 10vh;
      justify-content: center;
}

.lead {
      text-align: center;
      font-family: Cera Pro;
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
}

h1 {
      font-size: 100px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      text-align: center;
      margin: 0;

}

.text-block {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      top: 20vh;
      gap: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
}

h2 {
      position: absolute;
      left: 0;
      bottom: 200px;
      color: #FFF;

      font-size: 72px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
}

h3 {
      font-size: 28px;

      font-weight: 500;
      margin: 40px 0 20px 0
}

h4 {
      color: #000;
      margin: 0;

      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
}


.row a,
p {
      color: #000;
      margin: 0;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
}

a {
      width: max-content;
}



.row {
      gap: 4px;
      display: flex;
      flex-direction: column;
}

.row a {
      text-decoration: none;
      border-bottom: 1px solid #000;
}

body {
      margin: 0;
      font-family: Cera Pro;
      color: #000
}

@media (min-width: 320px) and (max-width: 756px) {

      h1 {
            font-size: 30px;
            font-weight: 400;
      }

      .lead {
            font-size: 18px;

      }

      .brands {
            gap: 30px;
            bottom: 25vh;
      }

      .brands img {
            width: 40%;
            max-width: 200px;
      }

      .text-block {
            padding: 0 16px;
      }

      .row a,
      p {
            color: #000;
            margin: 0;
            font-size: 17px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
      }

      h3 {
            font-size: 24px;
            margin: 20px 0 15px 0;
      }

      h4 {
            font-size: 17px;
      }

      h2 {
            position: absolute;
            left: 16px;
            bottom: 120px;
            color: #FFF;

            font-size: 40px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
      }

      .content {
            width: 100%;
            margin: 0 0;
            padding: 40px 16px;
            box-sizing: border-box;
            overflow-x: scroll;
      }

      .content-list {
            width: 100%;
            margin: 0 0;
            padding: 40px 16px;
            box-sizing: border-box;
            overflow-x: scroll;
      }
}

@media (min-width: 756px) and (max-width: 1320px) {

      .row a,
      p {
            color: #000;
            margin: 0;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
      }

      .brands img {
            width: 200px;
      }

      .lead {

            font-size: 27px;

      }

      h1 {
            font-size: 70px;


      }



      h2 {
            position: absolute;
            left: 30px;
            bottom: 120px;
            color: #FFF;

            font-size: 56px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
      }

      h3 {
            font-size: 24px;
            margin: 20px 0 20px 0;
      }

      h4 {
            font-size: 20px;
      }

      .content {
            width: 100%;
            margin: 0 0;
            padding: 80px 30px;
            box-sizing: border-box;
            overflow-x: scroll;
      }

      .content-list {
            width: 100%;
            margin: 0 0;
            padding: 40px 30px;
            box-sizing: border-box;
            overflow-x: scroll;
      }
}</style>
