<template>
      <PopupTable v-if="isShowPopup" :brand="selectBrand" />
      <table v-if="!isMobile()" id="fifthTable">
            <thead>
                  <tr>
                        <template v-for="col in columns" :key="col + 1">
                              <th v-if="col != 'link'" v-on:click="sortTable(col)">{{ col.replace(/_/g, ' ') }}
                                    <div class="arrow" v-if="col == sortColumn"
                                          v-bind:class="ascending ? 'arrow_up' : 'arrow_down'"></div>
                              </th>
                        </template>

                  </tr>
            </thead>
            <tbody>
                  <tr :key="row + 1" v-for="row in rows">
                        <template :key="col + 2" v-for="col in columns">
                              <td v-if="col != 'link'">
                                    <a v-if="col === 'Brand_or_Gallery'" target="_blank" :href="row.link">{{
                                          row.Brand_or_Gallery }} </a>
                                    <span v-else>{{ row[col] }}</span>
                              </td>
                        </template>


                  </tr>
            </tbody>
      </table>
      <table v-else id="fifthTable">
            <tbody>
                  <tr :key="row + 1" v-for="row in rows">
                        <td>
                              <a v-on:click="switchShowPopup(row)">{{ row.Brand_or_Gallery}} </a>
                        </td>
                  </tr>
            </tbody>
      </table>
</template>

<script>
import brandList from '../assets/data.json'
import PopupTable from './PopupTable.vue'
export default {
      name: 'HelloWorld',
      components: {
            PopupTable
      },
      props: {
            msg: String
      },
      data() {
            return {
                  ascending: false,
                  sortColumn: '',
                  rows: brandList,
                  isShowPopup: false,
                  selectBrand: {}
            }
      },

      methods: {
            switchShowPopup(row) {
                  this.isShowPopup = !this.isShowPopup
                  if (row) {
                        this.selectBrand = row
                        console.log(row)
                  }
            },
            "sortTable": function sortTable(col) {
                  if (this.sortColumn === col) {
                        this.ascending = !this.ascending;
                  } else {
                        this.ascending = true;
                        this.sortColumn = col;
                  }

                  var ascending = this.ascending;

                  this.rows.sort(function (a, b) {
                        if (a[col] > b[col]) {
                              return ascending ? 1 : -1
                        } else if (a[col] < b[col]) {
                              return ascending ? -1 : 1
                        }
                        return 0;
                  })
            },
            isMobile() {
                  if (screen.width <= 760) {
                        return true;
                  }
                  else {
                        return false;
                  }
            }
      },
      created() {
            this.isMobile()
      },
      computed: {
            "columns": function columns() {
                  if (this.rows.length == 0) {
                        return [];
                  }
                  return Object.keys(this.rows[0])
            }
      }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
table {

      width: 100%;
      border-collapse: collapse;

      border: 1px solid #44475C;
      border-left: 0;
      border-right: 0;
}


table th:hover {
      background: red;
}

table tr {
      border-left: 0 !important;
}




table thead {
      position: -webkit-sticky;
      position: -moz-sticky;
      position: -o-sticky;
      position: -ms-sticky;
      position: sticky;
      top: 0;
}

table th {
      font-size: 18px;
      font-family: Cera Pro;
      text-align: left;
      background: #F1F1F1;
      color: #000;
      cursor: pointer;
      padding: 13px;
      min-width: 30px;
      border: 1px solid #000;
      border-left: 0;

}

table th:first-child {
      background-color: #fff !important;
      padding-left: 0;
}

table th:last-child {

      border-right: 0;
}

table th:hover {
      background: #DADADA !important;
}

table td {
      text-align: left;
      padding: 13px;
      font-size: 18px;
      font-family: Cera Pro;
      border-right: 1px solid #000;
}

table td:first-child {
      font-weight: 600;
      padding-left: 0;
}

table td:last-child {
      border-right: red;
}

table tbody tr:nth-child(2n) td {
      border-top: 1px solid #000;
      border-bottom: 1px solid #000;
}

table td a {
      color: #000;
      text-decoration: none;
}

.arrow_down {
      background-image: url(../assets/arrow.svg)
}

.arrow_up {
      background-image: url(../assets/arrowUp.svg)
}

.arrow {
      float: right;
      width: 12px;
      height: 15px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position-y: bottom;
}

@media (min-width: 756px) and (max-width: 1320px) {
      table th {
            font-size: 16px;
      }

      table td:first-child {
            font-size: 16px;
      }

      table td {
            font-size: 16px;
            padding: 12px 15px;
      }

      table td a {
            color: #000;
            text-decoration: dotted;
      }

      table {

            width: 100%;

            border-collapse: collapse;
            border: 1px solid #44475C;
            border-left: 0;
            border-right: 0;

      }

      table th:first-child {
            background-color: #fff !important;
            padding-left: 0;
      }

      table th:last-child {

            border-right: 0;
      }

      table thead {}
}
</style>
