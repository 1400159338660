<template>
       <div :style="{ backgroundImage: 'url(' + require(`@/assets/${img}.webp`) + ')' }" class="container">
            <div class="content">
                  <h2>{{text}}</h2>
            </div>
      </div>
</template>

<script>

export default {
      name: 'FullScreenBlock',
      props: {
            img: String,
            text:String
      },
}
</script>
